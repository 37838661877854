<template>
    <div ref="terminal" class="console" tabindex="0" @keydown.esc="leave()">
        <div class="header">
            <div class="l-stack">
                <h1 class="l-stack l-gap-5">ax-track - Message Stream</h1>
                <!--                <button @click="testMessage()">Test</button>-->
                <p>
                    Total: <span>{{ total }}</span> / Last minute:
                    <span>{{ totalLastMinute }}</span>
                </p>
                <p>
                    Now: <span>{{ now.toLocaleTimeString() }}</span>
                </p>
                <p>
                    Last: <span>{{ lastUpdate.toLocaleTimeString() }}</span
                    >, <span>{{ elapsed }}</span> seconds ago
                </p>
            </div>
            <button class="transparent" @click="pause()">
                <img
                    :src="paused ? iconPlay : iconPause"
                    width="50px"
                    alt="pause/play"
                />
            </button>
            <div class="ascii-art">{{ asciArt }}</div>
        </div>
        <br /><br />
        <div>
            <table>
                <tr v-for="line in linesDisplayed" :key="line.timestamp">
                    <td class="nowrap">{{ line.timestamp }}</td>
                    <td class="nowrap" :class="line.class">{{ line.type }}</td>
                    <td class="nowrap" :class="line.class">
                        {{ line.device }}
                    </td>
                    <td>
                        <ColoredJSON :json="line.json" />
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import ColoredJSON from '@/components/ColoredJSON'
import CustomSocketHelper from '@/mixins/CustomSocketHelper'

import iconPause from '@/assets/icons/pause.svg'
import iconPlay from '@/assets/icons/play.svg'

import cow1 from 'raw-loader!../../assets/ascii-art/cow1.txt'
import cow2 from 'raw-loader!../../assets/ascii-art/cow2.txt'
import cow3 from 'raw-loader!../../assets/ascii-art/cow3.txt'

export default {
    name: 'DashboardMessageStream',
    components: {
        ColoredJSON,
    },
    mixins: [CustomSocketHelper],
    props: {},
    data() {
        return {
            total: 0,
            lastUpdate: new Date(),
            now: new Date(),
            lastMessage: null,
            timestamps: [],
            linesDisplayed: [],
            linesIncoming: [],
            paused: false,
            debug: null,
            asciArt: cow1,
            displayMuh: true,
            JSONdepth: 0,
            iconPause,
            iconPlay,
        }
    },
    computed: {
        elapsed() {
            return Math.max(0, Math.round((this.now - this.lastUpdate) / 1000))
        },
        totalLastMinute() {
            return this.timestamps.length
        },
    },
    mounted() {
        setInterval(this.updateTime, 1000)
        this.$nextTick(() => this.$refs.terminal.focus())
    },
    sockets: {
        keepalive(message) {
            this.addMessage(
                new Date(),
                'KEEPALIVE',
                `DEVICE:${message.tracker}`,
                message
            )
        },
        position(message) {
            this.addMessage(
                new Date(),
                'POSITION',
                `DEVICE:${message.tracker}`,
                message
            )
        },
        sensor_data(message) {
            this.addMessage(
                new Date(),
                'SENSOR_DATA',
                `DEVICE:${message.tracker}`,
                message
            )
        },
        debug(message) {
            this.addMessage(new Date(), 'DEBUG', `AUTH`, message)
        },
    },
    methods: {
        leave() {
            this.$router.push({ name: 'dashboardOverview' })
        },
        pause() {
            if (this.paused) {
                this.linesDisplayed = this.linesIncoming.concat(
                    this.linesDisplayed
                )
                this.truncate(this.linesDisplayed)
                this.linesIncoming = []
            }
            this.paused = !this.paused
        },
        truncate(array) {
            if (array.length > 200) {
                array.length = 200
            }
        },
        testMessage() {
            let timestamp = new Date()
            this.addMessage(timestamp, 'DEBUG', `DEVICE:12345`, {
                tracker: 12345,
                timestamp: timestamp.toISOString(),
                array: [1, 2, [4, { key: true, key2: null }], 'abc'],
            })
        },
        updateTime() {
            this.now = new Date()
            if (this.asciArt !== cow3 || !this.displayMuh) {
                this.asciArt = this.asciArt === cow1 ? cow2 : cow1
                this.displayMuh = true
            } else {
                this.displayMuh = false
            }
            // exclude old values from the minute average:
            let idx = this.timestamps.findIndex(
                date => date - this.now > -60000
            )
            if (idx === -1) {
                this.timestamps = []
            } else {
                this.timestamps = this.timestamps.slice(idx)
            }
        },
        addMessage(date, type, device, message) {
            this.asciArt = cow3
            this.lastMessage = message
            this.total += 1
            this.lastUpdate = date
            this.timestamps.push(this.lastUpdate)

            let info = {
                class: type.toLowerCase(),
                type: type,
                device: `DEVICE: ${message.tracker}`,
                timestamp: `[${date
                    .getHours()
                    .toString()
                    .padStart(2, '0')}:${date
                    .getMinutes()
                    .toString()
                    .padStart(2, '0')}:${date
                    .getSeconds()
                    .toString()
                    .padStart(2, '0')}.${date
                    .getMilliseconds()
                    .toString()
                    .padStart(3, '0')}]`,
                json: message,
            }

            if (this.paused) {
                // when paused, store incoming messages
                this.linesIncoming.unshift(info)
                this.truncate(this.linesIncoming)
            } else {
                this.linesDisplayed.unshift(info)
                this.truncate(this.linesDisplayed)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
td {
    vertical-align: top;
    padding-right: 1em;
}

.nowrap {
    white-space: nowrap;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ascii-art {
    font-family: monospace;
    white-space: pre;
}

.keepalive {
    color: #00a4e1;
}

.sensor_data {
    color: #6eb236;
}

.position {
    color: #f0c674;
}

.debug {
    color: #c5c8c6;
}

.transparent {
    background-color: black;
    border-color: white;
    border-radius: 50%;
}

.console {
    overflow-y: visible;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 0;
    background-color: black;
    color: white;
    font: 1.3rem Inconsolata, monospace;
}
</style>
