<template>
    <span
        ><span v-if="json && json.constructor === Object"
            >{<span v-for="(val, key, idx) in json" :key="key"
                ><span v-if="idx !== 0">,&nbsp;</span
                ><span>{{ key }}:&nbsp;</span><ColoredJSON :json="val"/></span
            >}</span
        ><span v-else-if="json && json.constructor === Array"
            >[<span v-for="(el, i) in json" :key="i"
                ><span v-if="i !== 0">,&nbsp;</span
                ><ColoredJSON :json="el"/></span
            >]</span
        ><span v-else :class="json && typeof json">{{ json }}</span></span
    >
</template>

<script>
export default {
    name: 'ColoredJSON',
    props: {
        json: {
            type: [Object, Array, Number, Boolean, String],
            required: false,
            default: null,
        },
    },
}
</script>

<style lang="scss" scoped>
span {
    display: inline;
}

.number {
    color: #00a4e1;
}

.string {
    color: #6eb236;
}

.boolean {
    color: #f0c674;
}
</style>
